import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { View } from "../Containers"



export const Overlay = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #009adc78;
    backdrop-filter: blur(16px);
    width: 100vw;
    height: 100vh;
    z-index: 99;
`

 export const Container = styled(View)`
    position: relative;
    background: #ffffff;
    align-items: center;
    justify-content: center;
    padding: 24px;
    border-radius: 24px;
    width: 25vw;
    height: auto;
    flex-direction: column;

    @media (max-width: 1200px) {
        width: 60vw;
    }

    @media (max-width: 720px) {
        width: 90vw;
    }
`


 export const StatusImage = styled.img`
  width : 100%;
  height : 100%;
 
`