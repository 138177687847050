export const dummyData = [
    {
        "title": "Shinsekai #1162",
        "nftImage": "https://res.cloudinary.com/alchemyapi/image/upload/mainnet/eb8de26237a494ef8e7a2eb20d62e686.png",
        "tokenId": 2
    },
    {
        "title": "Shinsekai #114",
        "nftImage": "https://res.cloudinary.com/alchemyapi/image/upload/mainnet/68850b72b444ca878f126a691e8bb18c.png",
        "tokenId": 44
    },
    {
        "title": "Shinsekai #195",
        "nftImage": "https://res.cloudinary.com/alchemyapi/image/upload/mainnet/a85fc6539b79af53d5743f75b1a4dd64.png",
        "tokenId": 195
    },
    {
        "title": "Shinsekai #2073",
        "nftImage": "https://res.cloudinary.com/alchemyapi/image/upload/mainnet/3a34b7717cfffc3ada100a0d9fc7b6b3.png",
        "tokenId": 2073
    },
    {
        "title": "Shinsekai #2747",
        "nftImage": "https://res.cloudinary.com/alchemyapi/image/upload/mainnet/fa192a014b97fdac13bb27412d38b86b.png",
        "tokenId": 2747
    },
    {
        "title": "Shinsekai #3337",
        "nftImage": "https://res.cloudinary.com/alchemyapi/image/upload/mainnet/34f645309325ab415547cb456389fab1.png",
        "tokenId": 3337
    },
    {
        "title": "Shinsekai #3811",
        "nftImage": "https://res.cloudinary.com/alchemyapi/image/upload/mainnet/20fadcdf169312f9a7c09d60628a6d16.png",
        "tokenId": 3811
    },
    {
        "title": "Shinsekai #5497",
        "nftImage": "https://res.cloudinary.com/alchemyapi/image/upload/mainnet/ae01294238f89275c1bd060ef13f7d39.png",
        "tokenId": 5497
    },
    {
        "title": "Shinsekai #5975",
        "nftImage": "https://res.cloudinary.com/alchemyapi/image/upload/mainnet/10e53ecd86513e4570f15a5f461098e4.png",
        "tokenId": 5975
    },
    {
        "title": "Shinsekai #6168",
        "nftImage": "https://res.cloudinary.com/alchemyapi/image/upload/mainnet/051466ce31757109c66d693dadd6f7e3.png",
        "tokenId": 6168
    },
    {
        "title": "Shinsekai #7530",
        "nftImage": "https://res.cloudinary.com/alchemyapi/image/upload/mainnet/de6ec7f129fd9f8382f9be1f2323402c.png",
        "tokenId": 7530
    },
    {
        "title": "Shinsekai #9500",
        "nftImage": "https://res.cloudinary.com/alchemyapi/image/upload/mainnet/bb0fca75770a821fce54b4d627871d06.png",
        "tokenId": 9500
    }
]