import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Config, DAppProvider, Mainnet,Goerli, Rinkeby } from '@usedapp/core';


const config: Config = {
  networks: [Mainnet, Rinkeby,Goerli],
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: "https://eth-mainnet.alchemyapi.io/v2/sSiZWY0b6cDNqbgYlsOBTn3GwKynPXqE",
    [Goerli.chainId] : 'https://eth-goerli.g.alchemy.com/v2/Msz5OTb1AiOidGfSaWb-8XnWXOvZLyxN'
  }
}

ReactDOM.render(
  <React.StrictMode>
   
    <DAppProvider config={config}>
        <App />
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
