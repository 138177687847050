import { utils } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { useCall, useContractFunction } from '@usedapp/core';
import mintABI from '../abi/SmartContract.json';
import { SmartContract } from '../gen/types';

const mintInterface = new utils.Interface(mintABI);

const contractAddr: {
    [key: string]: {
        [ind: number]: string
    }
} = {
    "minter": {
        1: "0x98b82D9eFC577B1c3AA6578342121231db2B47b9",
        5: "0xD0e459B78Ab07E382b2C49de377378e04866d821",
    }
}


export const useTotalSupply = (chainId: number) => {
    const contract = new Contract(contractAddr.minter[chainId || 1], mintInterface) as SmartContract;
    const { value, error } = useCall({ contract: contract, method: 'totalSupply', args: [] }) ?? {};

    if (error) {
        console.error(error.message);
        return undefined;
    }

    return value?.[0];
}

// export const useSaleConfig = (chainId: number) => {
//     const contract = new Contract(contractAddr.minter[chainId || 1], mintInterface) as SmartContract;
//     const { value, error } = useCall({ contract: contract, method: 'saleConfig', args: [] }) ?? {};

//     if (error) {
//         console.error(error.message);
//         console.log("error here",error)
//         return undefined;
//     }

//     return value;
// }

export const useBalanceOf = (chainId: number,adress : string) => {
    const contract = new Contract(contractAddr.minter[chainId || 1], mintInterface) as SmartContract;
    const { value, error } = useCall({ contract: contract, method: 'balanceOf', args: [adress] }) ?? {};

    if (error) {
        console.error(error.message);
        return undefined;
    }

    return value?.[0];
}


//NOT RELEVANT
// export const useMaxSupply = (chainId: number) => {
//     const contract = new Contract(contractAddr.minter[chainId || 1], mintInterface) as SmartContract;
//     const { value, error } = useCall({ contract: contract, method: 'maxSupply', args: [] }) ?? {};

//     if (error) {
//         console.error(error.message);
//         return undefined;
//     }

//     return value?.[0];
// }

// export function useWLMint1(chainId: number) {
//     const contract = new Contract(contractAddr.minter[chainId || 1], mintInterface) as SmartContract;

//     const { state, send } = useContractFunction(contract, "portalAccess", { transactionName: "Mint Shinsekai" });
//     return { state, send };
// }

// export function useWLMint2(chainId: number) {
//     const contract = new Contract(contractAddr.minter[chainId || 1], mintInterface) as SmartContract;

//     const { state, send } = useContractFunction(contract, "reserveCorp", { transactionName: "Mint Shinsekai" });
//     return { state, send };
// }


// export function useWLMint3(chainId: number) {
//     const contract = new Contract(contractAddr.minter[chainId || 1], mintInterface) as SmartContract;

//     const { state, send } = useContractFunction(contract, "publicRegistration", { transactionName: "Mint Shinsekai" });
//     return { state, send };
// }

// export function usePublicMint(chainId: number) {
//     const contract = new Contract(contractAddr.minter[chainId || 1], mintInterface) as SmartContract;

//     const { state, send } = useContractFunction(contract, "publicSaleMint", { transactionName: "Mint Shinsekai" });
//     return { state, send };
// }


