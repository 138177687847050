import { State } from "@usedapp/core";
import styled,{keyframes} from "styled-components"
import { Page, View } from "../Containers";
import {MdQrCodeScanner} from 'react-icons/md';
import { Button } from '../Button';
import { useState } from "react";
import { useEffect } from "react";
import {ImCross} from 'react-icons/im';
import { clear } from "console";

const EnterRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(40%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
`;

const Pulse = keyframes`
  0% {
    filter: brightness(1);
    transform: scale(1.0);
  }
  25% {
    filter: brightness(1.1);
    transform: scale(1.05);
  }
  50% {
    filter: brightness(1);
    transform: scale(1.0);
  }
  100% {
    transform: scale(1);
  }
`

const NFTContainer2 = styled(View)`
  flex-direction : column;
  padding: 0.5vw;
  width: 23vw;
  height: 23vw;
  // background: linear-gradient(180deg, rgb(38, 158, 210,1), rgb(38, 158, 210,1));
  background: black;
  border-radius: 24px;
  box-shadow: 0px 16px 32px rgba(10, 10, 10, 0.5);
  opacity: 0;
  animation: ${EnterRight} 1s 0.5s cubic-bezier(0.77, 0, 0.175, 1) forwards;

  @media (max-width: 720px) {
    padding: 2vw;
    width: 75vw;
    height: 75vw;
    margin-bottom: 10px !important;
  }
`;


const VerifyBorder = styled(View)`
  padding: 0px 0px;
  animation: ${Pulse} 1.2s cubic-bezier(0.77, 0, 0.175, 1) forwards infinite;
  margin : auto;
  margin-top : 20px;
  width : fit-content;
  position:absolute;
  top : 0px;
  right:10px;


  @media (max-width: 720px) {
    margin-top : 1vh;
  }
`

const NFTImage = styled.img`
  width : 100%;
  height : 100%;

  @media (max-width: 720px) {
    width : 100%;
    height : 100%;

  }
 
`
const VerifyButton = styled(Button)`
  font-size: 180%;
  background : #dd7973;
  padding : 6px 13px 6px 13px;
  margin : 0px;
  border-radius : 20px;
`


const NFTitle = styled.h1`
  font-size : 2.5rem;
  position : absolute;
  bottom : 0;
  left : 15px;
  color :yellow;
`


interface CardProps{
  imageSourceToken : string,
  title : string,
  account : string | undefined,
  token : string | undefined,
  balance : number | undefined,
  tokenId : number
}


export const Card: React.FC<CardProps> = ({ imageSourceToken,title,account,token,balance,tokenId }) => {
  const [qrData ,setQrData] = useState({
    walletId : "",
    ticketsNumber : 0,
    tokenId : 0
  });

  const [qrCodeData,setQrCodeData] = useState<string>();
  const [qrStatus,setQrStatus] = useState<boolean>(false);
  const [qrStop,setQrStop] = useState<boolean>(false);
  var timer:null | ReturnType<typeof setTimeout > = null;

  const callApiQr = async() =>{
    console.log("Data sent ",JSON.stringify(qrData))
    //http://localhost:8000/checker
    //https://new-app-ticket.herokuapp.com/checker
    const res = await fetch("https://new-app-ticket.herokuapp.com/checker",{
      method : 'POST',
      headers: {
        "Content-type": "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body : JSON.stringify(qrData)
    })

    const data = await res.json()
    console.log("DATA",data)

    if(data !== null){
        setQrCodeData(data)
        setQrStatus(true);
        timer = setTimeout(callApiQr,5000);
        console.log("Request made in timeout");
    }
    else{
      alert("You are not allowed acces")
      window.location.reload()
    }
  }

 

  useEffect(() =>{
    const wallet = qrData.walletId
    const bal = qrData.ticketsNumber
    if(wallet.length !== 0) {  
      callApiQr()
    }

  },[qrData])

  const handleVerify = () =>{
    if(account){
      let updatedValue = {}
      updatedValue = {walletId : account, ticketsNumber : balance}
      console.log("handle verify log",updatedValue)
      setQrData(
        {
          walletId : account,
          ticketsNumber : balance!,
          tokenId : tokenId
        }
      )
    }
  }

  const handleCrossPressed = () =>{
    window.location.reload()
  }

    return(
      <NFTContainer2> 
        {qrStatus ? <NFTImage src={qrCodeData} style={{margin : 'auto',width:'72%',height:'72%'}}  /> : <NFTImage src={imageSourceToken}  />}
        {qrStatus ? 
        <>
        <VerifyBorder>
            <VerifyButton onClick={handleCrossPressed}>
              <ImCross style={{ transform: 'scale(1.1)',marginTop:'5px'}}/>
            </VerifyButton>
        </VerifyBorder></> :
        <>
          <VerifyBorder>
              <VerifyButton  onClick={handleVerify}>
                  <MdQrCodeScanner style={{ transform: 'scale(1.6)',marginTop:'5px'}}/>
              </VerifyButton>
          </VerifyBorder>
          <NFTitle>
            {title}
          </NFTitle>
        </>}
      </NFTContainer2>

    );
}

