import styled from 'styled-components';

export const Button = styled.button`
    background-color: black;
    color: white;
    fill: white;
    border: none;
    border-radius : 30px;
    margin: 0px 5px;
    padding: 12px 32px;
    width: auto;
    font-size: 250%;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    
    &:hover {
        background-color: white;
        color: black;
        fill: black;
    }
    
    &:hover > svg {
        color: white;
        fill: black;
    }
`