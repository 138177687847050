import styled from 'styled-components';
import { Overlay } from '../Overlay';
import { Container } from '../Overlay';



export const Loader = () => {

    const OverlayLoader = styled(Overlay)`
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        height:auto;
        background: #44434978;
        
    `

    const LoaderImage = styled.img`
        width : 50%;
        height: 50%;
        @media (max-width: 720px) {
          width : 30%;
          height: 30%;
          
        }
    `

    const ContainerLoader = styled(Container)`
        background : #ffffff30;
        @media (max-width: 720px) {
          width : 50%;
          height: 30%;
          margin:auto;
          margin-top:15vh;
          
        }
    
    `
   
    return(
    <>
      <OverlayLoader>
        <ContainerLoader>
          <LoaderImage src={process.env.PUBLIC_URL + "images/loader.gif"}/>
          <p style={{textAlign: 'center',fontSize:'2rem'}}>Verifying Ownership and getting your nfts...</p>
        </ContainerLoader>
      </OverlayLoader>
    </>

    );
}