import { useEthers } from '@usedapp/core';
import { useCallback, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Button } from './components/Button';
import { Page, View } from './components/Containers';
import { Navbar } from './components/Navbar';
import { ReactComponent as Logo } from './images/logo.svg';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3Modal from 'web3modal';
import { ethers, utils } from 'ethers';
import { useBalanceOf, useTotalSupply } from './hooks';
// import tokenVideo from './components/TransactionStatus/video/minting.mp4';
// import { useAuth0 } from '@auth0/auth0-react';
import { Card } from './components/Card';
import { dummyData } from './components/Card/data';
import { Loader } from './components/Loader';



const EnterLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-40%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
`

const EnterRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(40%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
`



const Footer = styled(View)`
  width: 100vw;
  height: 4vh;
  background: #a2a2a2;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;

  @media (max-width: 720px) {
    display: flex;
  }
`;

const Background = styled(View)`
  background: url('images/background2.jpeg');
  background-size: cover;
  background-position: center 30%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
`

const Content = styled(View)`
  flex-direction: row;
  flex-wrap : wrap;
  align-items: center;
  z-index: 2;
  overflow : scroll;
  & > div {
    margin:auto;
    margin-top:11vh;
  }
  
  @media (max-width: 720px) {
    & > div {
      margin:auto;
      margin-top:16vh;
    }
    
  }
`


const Column = styled(View)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateX(-100%);
  animation: ${EnterLeft} 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;

  & > p {
    transform: translateY(-80%);
  }

  @media (max-width: 720px) {
    margin-bottom: 24px;
  }
`

const NFTContainer = styled(View)`
  flex-direction : column;
  padding: 0.5vw;
  width: 23vw;
  height: 23vw;
  // background: linear-gradient(180deg, rgb(38, 158, 210,1), rgb(38, 158, 210,1));
  background: black;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0px 16px 32px rgba(10, 10, 10, 0.5);
  opacity: 0;
  animation: ${EnterRight} 1s 0.5s cubic-bezier(0.77, 0, 0.175, 1) forwards;
  
  @media (max-width: 720px) {
    padding: 2vw;
    width: 75vw;
    height: 75vw;
    margin-bottom: 10px !important;
  }
`;



const NFTImage = styled.img`
  width : 50%;
  height : 0%;
  border-radius : 20px;

  @media (max-width: 720px) {
    width : 70%;
    height : 70%;

  }
 
`

const Pulse = keyframes`
  0% {
    filter: brightness(1);
    transform: scale(1.0);
  }
  25% {
    filter: brightness(1.1);
    transform: scale(1.05);
  }
  50% {
    filter: brightness(1);
    transform: scale(1.0);
  }
  100% {
    transform: scale(1);
  }
`

const VerifyBorder = styled(View)`
  padding: 0px 0px;
  animation: ${Pulse} 1.2s cubic-bezier(0.77, 0, 0.175, 1) forwards infinite;
  margin : auto;
  margin-top : 20px;
  width : fit-content;
  position:absolute;
  bottom : 10px;
  right:10px;


  @media (max-width: 720px) {
    margin-top : 1vh;
  }
`

const VerifyButton = styled(Button)`
  font-size: 180%;
  background : #dd7973;
  padding : 8px 18px 8px 18px;
  margin : 0px;
  border-radius : 20px;
`

function App() {

  const { account, activate, chainId } = useEthers();

  const totalSupply = useTotalSupply(chainId!);
  const balance = useBalanceOf(chainId!,account!)?.toNumber();
  
  const [token,setToken] = useState<string>('');
  const [buttonValue,setButtonValue] = useState<boolean>(true);
  const [isLoading,setIsLoading] = useState<boolean>(false);


  const onConnect = async () => {
    try {
      const providerOptions = {
        injected: {
          display: {
            name: 'Metamask',
            description: 'Connect with the provider in your Browser',
          },
          package: null,
        },
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              1: "https://eth-mainnet.alchemyapi.io/v2/sSiZWY0b6cDNqbgYlsOBTn3GwKynPXqE",
              5: "https://eth-goerli.g.alchemy.com/v2/Msz5OTb1AiOidGfSaWb-8XnWXOvZLyxN"
            }
          },
        },
      }

      const web3Modal = new Web3Modal({
        providerOptions,
      });

      const provider = await web3Modal.connect();
      await activate(provider);
      
    } catch (error) {
      console.error(error);
    }
  }

  const setUpToken = async () =>{
    setIsLoading(true)
    const response = await fetch('https://new-app-ticket.herokuapp.com/login',{
      method : 'POST',
      headers : {
        "Content-type": "application/json" 
      },
      body : JSON.stringify({wall : account})
    })
    console.log('Response LOGIN',response.status)
    if(response.status === 401){
      alert("YOUR ARE NOT ALLOWED ACCESS")
      window.location.reload()
    }
    else{
      const data = await response.json()
      console.log('Data LOG IN ',data)
      setToken(data.token)
      setIsLoading(false)
      setButtonValue(false)
    } 
  }

  useEffect( () =>{
    if(account!){
      setUpToken()
      console.log("TOKEN SET",token)
    }
  },[account])

  
  // useEffect(() => {

  //   if(balance === 0){
  //     console.log("got herrreeeeee")
  //     console.log('BalanceCheck : ', balance);

  //     alert("Vous n'avez pas de ticket pour ce Match")
  //     window.location.reload();

  //   }
  // }, [balance])


  useEffect(() => {
    console.log('🔗 Chain ID : ', chainId);
    console.log('👤 Account : ', account);
    console.log('Balance : ', balance);


  }, [account, chainId, totalSupply,balance])


  // const fetchMetadata = async() =>{
  //   const responseMeta = await fetch("http://localhost:8000/getNFTSMetadata",{
  //     method : 'POST',
  //     headers : {
  //       "Content-type": "application/json"
  //     },
  //     body : JSON.stringify({wallet : account})
  //   })
  //   console.log("METADATA",responseMeta,"BODY",responseMeta.body)
  //  return responseMeta.body

  // }
  // useEffect(()=>{
  //    const dataArr = fetchMetadata()
  // },[account])

  return (
    
    <Page style={{ background: 'rgba(255, 0, 0, 0.2)', flexDirection: 'column', height: '100%', position: 'relative' }}>
      <Background>
        {/* <Stripe /> */}
      </Background>
      <Navbar />
      <Content>
        {
            account && isLoading === false &&
            
            dummyData.map(item => <Card imageSourceToken={item.nftImage} title = {item.title} account = {account} token = {token} balance = {balance} tokenId={item.tokenId}/> )
        }
        {
          isLoading &&
          <Loader/>
          
        }
         
          {
            account ?

             <></>
              :
              <Column style={{marginTop: '3vh'}}> 
                <NFTImage src={process.env.PUBLIC_URL + "images/acceuil.png"}/>
                <Button onClick={onConnect} style={{marginTop:'20px'}}>CONNECT WALLET</Button>
              </Column>
          }
        
        
        {/* <NFTContainer> */}
          {/* <NFTReflection /> */}
          {/* <NFTImage src={process.env.PUBLIC_URL + "images/16.jpg"} /> */}
          {/* <Video src={tokenVideo} isMuted />
        </NFTContainer> */}
      </Content>
      <Footer>
        <p>
          TICKETING-2022
        </p>
      </Footer>
    </Page>
  );
}

export default App;


